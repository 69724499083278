<template>
    <section class="compras-main">
        <navbar-admin icon="icon-shopping"  />
        <tabs :tabs="tabsCompras" />
        <router-view />
    </section>
</template>
<script>
export default {
    data(){
        return{
            titulo:'',
            tabsCompras: [
                {
                    titulo:'Ordenes de compra',
                    ruta:"compras.ordenes",
                },
                {
                    titulo:'Facturas',
                    ruta:"orden.compra.facturas",
                },
            ],
        }
    },
}
</script>